/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import FacebookMessenger from "./FacebookMessenger"
import DealerButton from "./DealerButton"
import Seo from "./seo"

const Layout = ({ children }) => {

  return (
    <div className="relative">
      <Seo title={'Sirisun Sunscreen'} lang={'th'} meta={[]}/>
      <header>
        <StaticImage
          className="top-4 md:visible invisible transform -translate-x-1/2"
          style={{ left: "50%", position: "absolute" }}
          src={"../images/logo.png"}
          alt={"Logo"}
          width={150}
        />
      </header>
      <FacebookMessenger facebookAppId="941309289332785" />
      <main>
        {children}
      </main>
      <footer
        className="absolute w-screen -bottom-36 bg-footer bg-cover lg:h-60 h-52 flex flex-col justify-center items-center">
        <div className="absolute top-0">
          <DealerButton />
        </div>
        <StaticImage className="md:w-auto md:h-auto w-3/5" src={"../images/logo.png"} alt={"Logo"} />
      </footer>
    </div>
  )
}

export default Layout
