import * as React from "react"

interface FacebookMessengerProps {
  facebookAppId: string
}

const FacebookMessenger: React.FC<FacebookMessengerProps> = ({ facebookAppId }) => {
  React.useEffect(() => {
    // @ts-ignore
    window.fbAsyncInit = function() {
      // @ts-ignore
      window.FB.init({
        xfbml            : true,
        version          : 'v12.0'
      });
    };

    (function(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
      js.src = 'https://connect.facebook.net/th_TH/sdk/xfbml.customerchat.js';
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'))
  })

  return (
    <>
      <div id="fb-root" />
      <div
        className="fb-customerchat"
        // @ts-ignore
        attribution="biz_inbox"
        page_id={facebookAppId}
      />
    </>
  )
}

export default FacebookMessenger