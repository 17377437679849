import * as React from "react"

// @ts-ignore
import Dealer from "../images/icon-dealer.png"

interface BuyButtonProps {
}

const BuyButton: React.FC<BuyButtonProps> = ({}) => {
  return (
    <button
      className="bg-primary-teal-400 hover:bg-primary-teal-500 rounded-full text-white font-medium px-6 py-3 md:px-12 md:py-4 flex items-center z-20"
      style={{ boxShadow: "0px 1px 20px #FFFFFF" }}
      onClick={() => {
        window.open("https://lin.ee/v4fFedS", "_blank")
      }}
    >
      <img className="mr-2" src={Dealer} alt={"Dealer Icon"} width={24}
           height={24} /> สมัครเป็นตัวแทนกับเรา!
    </button>
  )
}

export default BuyButton